import React from "react";
import styled from "styled-components";
import blogImage from "../images/crowd1.jpg";

const StyledNewsItem = styled.section`
  background-color: var(--background-color2);
`;

const NewsSingle = () => {
  return (
    <StyledNewsItem className="causes-detail-area news-detail-area">
      <div className="container">
        <div className="row blog-content-wrap justify-content-center">
          <div className="col-lg-8">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img className="img-fluid" src={blogImage} alt="" />
                  <span className="blog__date">27 March, 2022</span>
                </div>
                <div className="blog-inner-content">
                  <div className="inner-causes-box">
                    <h3 className="blog__title">
                      Action for Democracy has decided to support three
                      Hungarian civic initiatives
                    </h3>
                    <ul className="blog__list">
                      <li className="blog__dot-active">
                        March 27, 2022 New York
                      </li>
                      <li className="blog__dot-active">Action for Democracy</li>
                      <li>Press release</li>
                    </ul>
                  </div>
                </div>
                <div className="blog-inner-content-2">
                  <p className="causes__text">
                    The Print-It-Yourself! movement, local government action to
                    help refugees in Ukraine, and the restoration of democratic
                    values and a system of checks and balances - these are the
                    three areas that Action for Democracy has decided to
                    support. The recently formed NGO considers Hungary,
                    especially after the Russian invasion of Ukraine, to be a
                    battleground state in the struggle for democracy, and
                    therefore pays special attention to local projects.
                  </p>
                  <br />
                  <p className="causes__text">
                    As a non-profit, non-partisan organization, Action for
                    Democracy aims to actively support initiatives to protect
                    and restore democratic values. Accordingly, he considers the
                    April 3 elections to be a particularly important milestone
                    in strengthening the Hungarian democratic system. The war
                    against Ukraine was also an attack on Western democratic
                    values, so during its first co-operation in Hungary, Action
                    for Democracy also focused on supporting Ukrainian refugees.
                  </p>
                  <br />
                  <p className="causes__text">
                    Freedom of expression and information - In the last 12
                    years, the Hungarian government has made the so-called
                    public service media a tool of party propaganda and has
                    blocked a significant part of the Hungarian population from
                    objective information due to its massive dominance of the
                    media market. In addition to freedom of expression, the
                    Charter of Fundamental Rights of the European Union clearly
                    states the right to freedom of information. The
                    Print-It-Yourself! movement uses simple but powerful tool, a
                    kind of “XXI. century samizdat ”in order to ensure that
                    everyone has access to balanced information. Action for
                    Democracy is proud to support this organization that seeks
                    to restore fundamental rights.
                  </p>
                  <br />
                  <p className="causes__text">
                    The system of checks and balances - One of the basic
                    knowledge of an illiberal state is the desire for supremacy.
                    The Orbán government has been systematically dismantling the
                    system of checks and balances since 2010, not secretly with
                    the aim of making it impossible for the opposition to secure
                    its own power in the long run, even after losing its
                    government role. Action for Democracy has chosen to support
                    the Movement for All in Hungary (MMM) because it sees this
                    long-standing NGO as committed to rebuilding democracy and
                    restoring a system of checks and balances. The support
                    provided to MMM comes exclusively from donations from
                    Hungarian citizens.
                  </p>
                  <br />
                  <p className="causes__text">
                    Humanitarian aid - Hungary's neighborhood is experiencing
                    the bloodiest war in Europe in seven decades. The Hungarian
                    people, non-governmental organizations and local governments
                    are helping the refugees of Ukraine in an exemplary manner
                    from the very first moment, thus replacing the decades-long
                    destruction of the Orbán government and thus showing the
                    world the more beautiful face of Hungary. The Action for
                    Democracy has therefore decided to provide financial
                    assistance to the municipality of Miskolc and to set up a
                    civil coordination coordination council to coordinate the
                    work of non-governmental organizations and municipalities
                    dealing with refugees.
                  </p>
                  <br />
                  <p className="causes__text">
                    As a reminder, since mid-February, Action for Democracy has
                    been running a campaign called Hungarian Spring to promote
                    the participation of Hungarians living in the diaspora and
                    is organizing a Hungarian gastro-cultural event in seven
                    Western European cities on April 3 to boost elections. who
                    have a long way to go to the polls.
                  </p>
                  <div className="news-tags"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledNewsItem>
  );
};

export default NewsSingle;
